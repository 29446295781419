import React, {useState} from 'react'
import Fade from 'react-reveal/Fade';

const ContactForm = (props) => {

    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [phone, setPhone] = useState(null)
    const [message, setMessage] = useState(null)
    const [type] = useState("F")

    function sendEmail(e) {
        e.preventDefault();

        fetch('https://api.cash2pay.com.br/lead', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "name": name,
                    "email": email,
                    "phone": phone,
                    "message": message,
                    "contactType": type
                }
            )
        })
            .then(response => response.json())
            .then(response => console.log(JSON.stringify(response)))

        e.target.reset()
    } 

    return (
        <div className="ras-contact-form">
            <Fade right>
                <form id="contact-form" onSubmit={sendEmail}>
                    <div className="form-group">
                        <label for="name" className="sr-only">Qual é o seu nome?</label>
                        <input type="text" name="user_name"
                               onChange={(e)=>setName(e.target.value)}
                               className="form-control" id="name" placeholder="Qual é o seu nome?" />
                    </div>

                    <div className="form-group">
                        <label for="email" className="sr-only">Qual é o seu endereço de e-mail?</label>
                        <input type="email" name="user_email"
                               onChange={(e)=>setEmail(e.target.value)}
                               className="form-control" id="email" placeholder="Qual é o seu endereço de e-mail?" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="text" className="sr-only">Qual é o seu número de telefone?</label>
                        <input type="text"
                               onChange={(e)=>setPhone(e.target.value)}
                               name="user_phone" className="form-control" id="email"
                               placeholder="Qual é o seu número de telefone?"/>
                    </div>

                    <div className="form-group">
                        <label for="message" className="sr-only">Mensagem</label>
                        <textarea
                            onChange={(e)=>setMessage(e.target.value)}
                            className="form-control" id="message" rows="4" placeholder="Nos diga aqui o quê precisa, nós vamos te ajudar"></textarea>
                    </div>

                    <div className="form-group mb-0">
                        <button className="btn btn-primary btn-large">Enviar</button>
                    </div>
                </form>
            </Fade>
        </div>
    );

}

export default ContactForm;