import blogImg1 from '../../assets/images/blog/fornecedores-a.png';
import blogImg2 from '../../assets/images/blog/formo-hub.png';
import blogImg3 from '../../assets/images/blog/cashtoflow.png';
import PostContentFornecedoresAmericanas from './posts/PostContentFornecedoresAmericanas';
import PostContentExpansaoMorro from "./posts/PostContentExpansaoMorro";
import PostContentSobreCashToFlow from "./posts/PostContentSobreCashToFlow";

const BlogData = {

    list: [
        {
            id: 1,
            blogImage: blogImg1,
            blogTitle: 'Como um Fornecedor poderia estar protegido no caso Americanas',
            blogAuthor: 'Johnnatha Santos',
            blogPublishedDate : '17 de Jan de 2023',
            url: 'como-um-fornecedor-poderia-estar-protegido-caso-americanas',
            PostPage: <PostContentFornecedoresAmericanas
                    blogTitle = 'Como um Fornecedor poderia estar protegido no caso Americanas'
                    blogAuthor = 'Johnnatha Santos'
                    blogPublishedDate = '17 de Jan de 2023'
            />
        },
        {
            id: 2,
            blogImage: blogImg2,
            blogTitle: 'Nossa expansão e uma nova franquia inspiradora no Morro da Cruz',
            blogAuthor: 'Johnnatha Santos',
            blogPublishedDate : '26 de Jan de 2023',
            url: 'expansao-franquia-morro-da-cruz',
            PostPage: <PostContentExpansaoMorro
                blogTitle = 'Nossa expansão e uma nova franquia inspiradora no Morro da Cruz'
                blogAuthor = 'Johnnatha Santos'
                blogPublishedDate = '26 de Jan de 2023'
            />
        },
        {
            id: 3,
            blogImage: blogImg3,
            blogTitle: 'Sobre a CashToFlow e como Conectamos Fornecedores e Lojistas',
            blogAuthor: 'Johnnatha Santos',
            blogPublishedDate : '20 de Nov de 2022',
            url: 'sobre-a-cashToFlow-e-como-conectamos-fornecedores-e-lojistas',
            PostPage: <PostContentSobreCashToFlow
                blogTitle = 'Sobre a CashToFlow e como Conectamos Fornecedores e Lojistas'
                blogAuthor = 'Johnnatha Santos'
                blogPublishedDate = '20 de Nov de 2022'
            />
        }
    ]
}

export default BlogData