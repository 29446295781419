
import React from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

import diamondShape from "../../assets/images/diamond-shape.svg";
import curlyShape from "../../assets/images/curly-shape.svg";
import bannerDots from "../../assets/images/banner-dots-1.svg";
import triangle from "../../assets/images/triangle.svg";

import Logo from '../../assets/images/logos/logo.svg';
import {AppDomain} from "../../data/AppDomains";

const mailApi =
    `mailto:${AppDomain.contact.mail}`;

const whatsApi =
    `https://api.whatsapp.com/send?phone=${AppDomain.contact.phone}`;

const Footer = (props) => {
    const { footerLogo, footerClass, footerCTA } = props;
    return (
        <footer className={footerClass ? footerClass : 'ras-footer ras-footer-1'}>
            <div className="container">
                {
                    footerCTA ? 
                    <div className="row">
                        <div className="col-lg-12">
                            <Fade bottom>
                                <div className="ras-calltoaction">
                                    <h3>Avalie seu <strong>limite de crédito</strong>  <br />e comece logo a <strong>comprar</strong> para <strong>pagar depois</strong>: fale com a gente.</h3>
                                    <Link to="/contato" className="btn btn-primary btn-large">Contato</Link>
                                </div>
                            </Fade>
                        </div>
                    </div> : ''
                }
                
                <Fade left cascade>
                    <div className="row">
                        <div className="col-lg-5 col-md-12 ras-widget-about">
                            <div className="ras-about-logo">
                                <Link to="/"><img src={footerLogo ? footerLogo : Logo} alt="Footer Logo" /></Link>
                            </div>

                            <p className="ras-about-desc">Um novo jeito de
                                ajudar
                                <br /> o empreendedor no Brasil.</p>

                            <ul className="ras-social-profile">
                                <li><a href={AppDomain.socialMedia.instagram} target={"_blank"}><i className="icofont-instagram"></i></a></li>
                                <li><a href={AppDomain.socialMedia.linkedin} target={"_blank"}><i className="icofont-linkedin"></i></a></li>
                            </ul>

                        </div>
                        <div className="col-lg-3 col-md-12 ras-widget-links">
                            <h3 className="ras-widget-title">Links</h3>
                            <ul>
                                <li><Link to="/fornecedor">Fornecedor</Link></li>
                                <li><Link to="/lojista">Lojista</Link></li>
                                <li><Link to="/contato">Contato</Link></li>
                                <li><Link to="#">Politica de Privacidade</Link></li>
                                <li><Link to="#">Termos de uso</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-12 ras-widget-subscribe">
                            <div className="ras-about-address">
                                <p>{AppDomain.org.name}<br/>
                                    CNPJ: {AppDomain.org.legalId}</p>
                                <p className="mb-0">Contato:</p>
                                <p><a href={whatsApi} target={"_blank"}>{AppDomain.contact.phoneFormatted}</a>
                                    <span className="email"><a href={mailApi} target={"_blank"}>{AppDomain.contact.mail}</a></span></p>
                            </div>
                        </div>
                    </div>
                </Fade>

                <div className="ras-copyright mt-60">
                    <div className="row ras-copyright-wrapper relative">
                        <Fade bottom>
                            <div className="col-md-6 text-lg-center">
                                <p className="copyright-text mb-0">{AppDomain.copyright}</p>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <div className="ras-footer-shapes">
                <img src={diamondShape} alt="#Lojista Tenha limite de crédito e prazo para comprar estoque hoje e pagar depois" className="diamond-shape" />
                <img src={triangle} alt="Um jeito inteligente de usar os recebíveis" className="triangle-shape" />
                <img src={curlyShape} alt="No Cash2Pay o lojista pode utilizar o saldo das maquininhas para efetuar pagamentos, obter crédito e prazo para pagar suas compras via boleto e Pix." className="curly-shape" />
                <img src={bannerDots} alt="#Lojista compre agora, e pague só depois, tenha crédito e prazo" className="square-dots-1" />
            </div>
        </footer>
    );
}

export default Footer;