import React from 'react';
import HomeTwoMain from './HomeTwoMain';
import FooterTwo from '../../components/Footer/FooterStyleTwo';

import Logo from '../../assets/images/logos/logo-colored.svg';
import Header from "../../components/Header";

class HomeTwo extends React.Component {
    render() {
        return (
            <React.Fragment>

                <Header
                    parentMenu='home'
                    headerNormalLogo={Logo}
                    headerClass='ras-header ras-header-2 ras-header-2-alt'
                />

                <HomeTwoMain />
                <FooterTwo 
                    footerCTA='enable'
                />
            </React.Fragment>
        );
    }
}


export default HomeTwo;

