import React from 'react';
import Fade from 'react-reveal/Fade';
import SingleService from '../../components/Service/SingleService';

const Service = () => {

    return (
        <div className="ras-services ras-services-1 ras-service-1-spacer-bottom mb-50">
            <div className="container">
                <Fade bottom>
                    <h2 className="mb-0 text-center">
                        Nós ajudamos centenas
                    </h2>
                    <h2 className="mb-50 text-center">
                        de <span className="color-title">fornecedores</span> a venderem mais com menos risco.
                    </h2>

                    <h2 className="mb-50 text-center">
                        <span className="color-title">Benefícios da Nossa Plataforma de Pagamentos B2B</span>
                    </h2>
                </Fade>
                <Fade left cascade>
                    <div className="row">
                        <div className="col-lg-4">
                            <SingleService
                                itemClass="ras-services-item"
                                Icon='flaticon-web-programming'
                                Title="Condições de pagamento flexíveis"
                                Desc="Escolha as condições de pagamento para seus clientes de 7 a 120 dias, de acordo com a necessidade de sua empresa."
                            />
                        </div>
                        <div className="col-lg-4">
                            <SingleService
                                itemClass="ras-services-item"
                                Icon='flaticon-digital-marketing'
                                Title="Várias opções de pagamento"
                                Desc="Ofereça aos clientes as opções de pagamento B2B mais atrantes, com limites de crédito de até 8x o volume de vendas."
                            />
                        </div>
                        <div className="col-lg-4">
                            <SingleService
                                itemClass="ras-services-item"
                                Icon='flaticon-vector'
                                Title="Aumento do ticket médio dos pedidos"
                                Desc="Ofereça mais prazos para seus clientes, mais limite de crédito e uma experiência fluída de Checkout B2B."
                            />
                        </div>
                    </div>
                </Fade>
            </div>
        </div>
    );
}

export default Service;