import React from 'react';
import Slider from "react-slick";
import Fade from 'react-reveal/Fade';
import SinglePostTwo from '../../components/Blog/SinglePostTwo';

import BlogData from '../blog/BlogData';

const Blog = () => {

    const testimonialSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        centerMode: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                    arrows: false,
                }
            },
            {

                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                }
            }
        ]
    };

    return (
        <React.Fragment>
           <div className="ras-blog ras-blog-2 ras-blog-2-spacer">
                <div className="ras-blog-2-slider">
                    <div className="container">
                        <Fade bottom>
                            <h2 className="text-center mb-0">
                                Veja as dicas mais recentes e populares
                            </h2>
                            <h2 className="text-center mb-50">
                                <span className="color-title">de nossos especialistas</span>
                            </h2>
                        </Fade>
                        <div className="ras-blog-2-slider">
                            <Slider {...testimonialSettings}>
                                {
                                    BlogData.list.map((p)=>{
                                        return (
                                            <SinglePostTwo
                                                blogImage = {p.blogImage}
                                                blogTitle =  {p.blogTitle}
                                                blogAuthor =  {p.blogAuthor}
                                                blogPublishedDate =  {p.blogPublishedDate}
                                                url =  {p.url}
                                            />
                                        )
                                    })
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

}

export default Blog;