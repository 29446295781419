import React from 'react';
import HomeMain from './HomeMain';
import HeaderStyleTwo from '../../components/Header/HeaderStyleTwo';

class HomePage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <HeaderStyleTwo
                    parentMenu='home'
                />
                <HomeMain />
            </React.Fragment>
        );
    }
}

export default HomePage;
