import React from 'react';
import Fade from 'react-reveal/Fade';
import Slider from "react-slick";
import RecentPost from '../Blog/RecentPost'

import BlogData from "../../pages/blog/BlogData";

const RecentPostWidget = () => {

    const blogSettings = {
        dots: true,
        arrows: false,
        infinite: true,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    return (
        <div className="ras-widget-recent">
            <Fade bottom>
                <h3 className="ras-widget-title mb-40">Publicações recentes</h3>
            </Fade>
            <Fade bottom>
                <div className="ras-recent-inner recentSlider">
                    <div className="slide">
                        <Slider {...blogSettings}>
                            <div className="ras-recent-items">
                                {
                                    BlogData.list.map((p)=>{
                                        return (
                                            <RecentPost
                                                postPublishedDate = {p.blogPublishedDate}
                                                postTitle = {p.blogTitle}
                                                postImage = {p.blogImage}
                                                url = {p.url}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </Slider>
                    </div>
                </div>
            </Fade>
        </div>
    )
}

export default RecentPostWidget;