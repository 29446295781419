import React from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

// Categories Image
import featureImg from '../../assets/images/features/checkout-b2b.png';
import {AppDomain} from "../../data/AppDomains";
const mailApi =
    `mailto:${AppDomain.contact.mail}`;

const CheckoutSection = () => {

    return (
        <div className="ras-features ras-features-1 sec-spacer-bottom-120 mb-50">
            <div className="container relative">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <Fade left>
                            <div className="ras-features-image">
                                <img src={featureImg} alt="our-features" />
                                <a className="support-mail" href={mailApi}>{AppDomain.contact.mail}</a>
                                <div className="features-color-dots"></div>
                                <div className="features-white-dots"></div>
                            </div>
                        </Fade>
                    </div>
                    <div className="col-lg-6">
                        <div className="ras-features-details">                                
                            <Fade right>
                                <h2 className="mb-0">Taxa de conversão mais alta com</h2>
                                <h2 className="mt-0"><strong>Checkout Buy Now, Pay Later B2B</strong></h2>
                                <p>Com Checkout B2B você aumenta a satisfação do cliente oferecendo soluções de pagamento digital, especializada nas necessidades dos clientes empresariais. 1) autenticação. 2) análise de crédito. 3) concessão de limite em tempo real durante o checkout.</p>
                            </Fade>
                            <Fade bottom>
                                <div className="features-btn text-left mt-4">
                                    <Link to="/contato" className="btn btn-large btn-primary">Entrar em contato</Link>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ras-blur-background"></div>
        </div>
    );
}

export default CheckoutSection;