import React from 'react';
import Fade from 'react-reveal/Fade';

const WhyChooseUs = () => {
    return (
        <div className="ras-why-choose-us ras-why-choose-us-2 ">
            <div className="container">
                <Fade bottom>
                    <h2 className="text-center mb-0">Algumas razões porquê</h2>
                    <h2 className="text-center mb-50">os clientes nos escolhem</h2>
                </Fade>
                <Fade bottom cascade>
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="choose-us-item">
                                <div className="choose-us-icon purple-bg-icon">
                                    <i className="flaticon-man"></i>
                                </div>
                                <div className="choose-us-details">
                                    <h3>Time preparado</h3>
                                    <p>Nosso time de especialistas em crédito empresarial estarão de mãos dadas com você no dia a dia.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="choose-us-item">
                                <div className="choose-us-icon orange-bg-icon">
                                    <i className="flaticon-checked"></i>
                                </div>
                                <div className="choose-us-details">
                                    <h3>Seguro e protegido</h3>
                                    <p>Foque seu time em vender e deixe conosco qualquer risco e suspeita de fraude.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="choose-us-item">
                                <div className="choose-us-icon skyblue-bg-icon">
                                    <i className="flaticon-man"></i>
                                </div>
                                <div className="choose-us-details">
                                    <h3>Suporte Online</h3>
                                    <p>Estamos no whats, e-mail, telefone, em qualquer canal pode chamar que estaremos disponíveis.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="choose-us-item">
                                <div className="choose-us-icon red-bg-icon">
                                    <i className="flaticon-chat"></i>
                                </div>
                                <div className="choose-us-details">
                                    <h3>Suporte Mais</h3>
                                    <p>Aqui na Cash, você tem um profisional exclusivo acompanhando sua operação e disponível.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
        </div>
    );
}
export default WhyChooseUs;