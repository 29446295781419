import { Link } from "react-router-dom";

const SinglePostTwo = (props) => {
  const {
    blogClass,
    blogImage,
    blogTitle,
    blogAuthor,
    blogPublishedDate,
    url,
  } = props;
  return (
    <div className={blogClass ? blogClass : "ras-blog-single"}>
      <div className="ras-blog-img">
        <Link to={`/blog/blog-details/${url}`}>
          <img src={blogImage} alt={blogTitle} />
        </Link>
      </div>
      <div className="ras-blog-info">
        <div className="ras-blog-meta">
          {/* <h3 className="ras-blog-user"><i className="icofont-user-alt-3"></i> <Link to={`/blog/blog-details/${url}`}> {blogAuthor}</Link></h3> */}
          <h3 className="ras-blog-user">
            <i className="icofont-clock-time"></i>{" "}
            <Link to={`/blog/blog-details/${url}`}> {blogPublishedDate}</Link>
          </h3>
        </div>

        <h3 className="ras-blog-title">
          <Link to={`/blog/blog-details/${url}`}>{blogTitle}</Link>
        </h3>

        <Link to={`/blog/blog-details/${url}`} className="ras-blog-read-more">
          <i className="icofont-long-arrow-right"></i>
        </Link>
      </div>
    </div>
  );
};

export default SinglePostTwo;
