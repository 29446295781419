import React, { useState } from "react";
import Fade from 'react-reveal/Fade';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor'; 

const Counter = () => {

    const [state, setState] = useState(true);

    const counters = [
        {
            countNum: 11151834.76,
            decimals: 2,
            countTitle: 'Em limite de crédito concedido',
            counterPrefix: '+',
            iconClass: 'ras-counter-icon bg-purple-icon',
            formattingFn: (count) => {
                return count.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
            }
        },
        {
            countNum: 293,
            decimals: 0,
            countTitle: 'Lojistas que já benefiaram com Cash2Pay',
            counterPrefix: '+',
            iconClass: 'ras-counter-icon bg-orange-icon',
            formattingFn: (count) => {
                return count
            }
        },
        {
            countNum : 7500000000.00,
            decimals: 2,
            countTitle: 'Nossa meta em concessão de crédito',
            counterPrefix: '+',
            iconClass: 'ras-counter-icon bg-red-icon',
            formattingFn: (count) => {
                 return count.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
            }
        }
    ];

    return(
        <div className="ras-counter">
            {counters &&
            <div className="row">
                {counters.map( (counter, num) => (
                <div key={num} className="col-md-4">
                    <Fade bottom>
                        <div className="ras-counter-1">
                            <div className={counter.iconClass}>
                                <i className="flaticon-strategy"></i>
                            </div>
                            <div className="ras-counter-info">
                                <h3><span className="ras-counter-number">
                                    <CountUp start={state ? 0 : counter.countNum}
                                             end={counter.countNum}
                                             duration={10}
                                             decimals={counter.decimals}
                                             formattingFn={counter.formattingFn}
                                             onEnd= {()=> setState(false)} />

                                             {({ countUpRef, start }) => (

                                                 <VisibilitySensor onChange={start} delayedCall>
                                                     <span ref={countUpRef} />
                                                 </VisibilitySensor>

                                        )}
                                </span> <span className="ras-counter-postfix">{counter.counterPrefix}</span></h3>
                                <p className="ras-counter-label">{counter.countTitle}</p>
                            </div>
                        </div>
                    </Fade>
                </div>
                ))}
            </div>
            }
        </div>
    )
}
export default Counter;