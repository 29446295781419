import React from 'react';
import Fade from 'react-reveal/Fade';
import ProgressBarCircle from '../../components/Progress/ProgressCircle'

import successImg from '../../assets/images/features/credit-engine.png'

const CreditEngineSection = () => {

    return (
        <div className="ras-success-faq faq-bottom-spacer mb-50">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <Fade left>
                            <div className="ras-success-story">
                                <h2 className="mb-0"><strong>Motor de Crédito</strong> perfeito para
                                </h2>
                                <h2 className="mt-0"># Fornecedores
                                </h2>

                                <p>Com nosso Motor de Crédito você faz analise de crédito em tempo real, além de
                                    acompanhar o limite disponível de seus clientes, gerenciar recebimentos, cobranças e
                                    liquidações. </p>
                                <p>
                                    E que tal, visualizar a capacidade de venda e agenda de recebíveis em todos os
                                    credenciadores e sub dos seus clientes comerciantes?
                                </p>
                            </div>
                        </Fade>

                        <p>
                            <span
                                className="color-title">Uma plataforma feita para eliminar os problemas, a seguir:</span>
                        </p>

                        <div className="ras-experience-progress mb-50">
                            <div className="row">
                                <div className="col-sm-4">
                                    <Fade bottom>
                                        <div className="ras-progress-wrapper">
                                            <ProgressBarCircle
                                                progress='15'
                                                title='Perda de vendas'
                                                strokeColor='#4d41e1'
                                                trackstrokeColor='#cccccc'
                                                Icon='flaticon-vector'
                                                iconClass='ras-progress-icon purple-bg-icon'
                                            />
                                        </div>
                                    </Fade>
                                </div>

                                <div className="col-sm-4">
                                    <Fade bottom>
                                        <div className="ras-progress-wrapper">
                                            <ProgressBarCircle
                                                progress='6'
                                                title='Inadimplência'
                                                strokeColor='#ff884e'
                                                trackstrokeColor='#cccccc'
                                                Icon='flaticon-digital-marketing'
                                                iconClass='ras-progress-icon orange-bg-icon'
                                            />
                                        </div>
                                    </Fade>
                                </div>

                                <div className="col-sm-4">
                                    <Fade bottom>
                                        <div className="ras-progress-wrapper">
                                            <ProgressBarCircle
                                                title='Custos com cobrança'
                                                progress='100'
                                                strokeColor='#36c1ea'
                                                trackstrokeColor='#cccccc'
                                                Icon='flaticon-web-programming'
                                                iconClass='ras-progress-icon skyblue-bg-icon'
                                            />
                                        </div>
                                    </Fade>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <Fade right>
                            <div className="ras-success-story-image">
                                <img src={successImg} alt="Our Success Story"/>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreditEngineSection;