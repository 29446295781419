import React, { useState } from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

import postImage from "../../../assets/images/blog/cashtoflow-detail.png";
import authorImage from "../../../assets/images/perfil.png";

const PostContentSobreCashToFlow = (props) => {
  return (
    <>
      <Fade bottom>
        <div className="ras-post-img">
          <img src={postImage} alt="Business Analysis for Growing Business" />
        </div>
      </Fade>
      <ul className="ras-post-meta">
        {/* <Fade bottom>
                    <li className="ras-post-user fadeInLeft">
                        <i className="icofont-user"></i>
                        <Link to="#">{props.blogAuthor}</Link>
                    </li>
                </Fade> */}
        <Fade bottom>
          <li className="ras-post-date fadeInLeft">
            <i className="icofont-ui-clock"></i>
            <span>{props.blogPublishedDate}</span>
          </li>
        </Fade>
        <Fade bottom>
          <li className="ras-post-tags fadeInLeft">
            <i className="icofont-tag"></i>
            <div className="ras-post-tag-list">
              <Link to="#">Lojista</Link>,&nbsp;
              <Link to="#">Fornecedor</Link>,&nbsp;
              <Link to="#">B2B</Link>,&nbsp;
              <Link to="#">BNPL</Link>
            </div>
          </li>
        </Fade>
      </ul>

      <Fade bottom>
        <h2 className="ras-post-title">{props.blogTitle}</h2>
      </Fade>
      <div className="ras-post-content">
        <Fade bottom>
          <p>
            Uma Solução Completa de Crédito B2B que conecta Fornecedores,
            Lojistas e Agentes de Crédito.
          </p>
          <p>Já ouviu falar em Buy Now, Pay Later para Checkouts B2B? </p>
          <p>
            O principal instrumente de pagamento nas compras empresariais ainda
            é o Boleto. Portanto, as relações de compra e venda entre Lojistas e
            seus Fornecedores carece de soluções mais refinadas de pagamentos
            para canais de venda B2B.
          </p>
          <p>
            A seguir, como a CashToFlow leva soluções de pagamento para Lojistas
            e Fornecedores, criando um ecossistema interconectado de crédito
            B2B.
          </p>
          <p className="mb-0">
            <strong>1) Motor de crédito:</strong>
          </p>
          <p>Perfeito para Fornecedores e Agentes de Crédito.</p>
          <p>
            <p className="mb-2">
              • Análise da capacidade de venda e agenda de recebíveis em todos
              os credenciadores e sub;
            </p>
            <p className="mb-2">
              • Registro de gravame e ônus de recebíveis para crédito com
              garantias e antecipação de recebíveis de cartão;
            </p>
            <p className="mb-2">
              • Gateway integrado nas 4 registradoras de mercado (CIP, TAG, CERC
              e B3);
            </p>
            <p className="mb-2">
              • Modelo preditivo de prevenção de colaterais, chargebak e
              recebíveis não performados e
            </p>
            <p className="mb-2">• APIs de integração.</p>
          </p>

          <p className="mb-0">
            <strong>2) Checkout B2B:</strong> <br />
          </p>
          <p>Fornecedor pode vender mais e sem riscos.</p>
          <p>
            <p className="mb-2">
              • Aumento da taxa de conversão de checkout, graças as opções de
              pagamentos flexíveis e prazos estendidos;
            </p>
            <p className="mb-2">
              • Aprovação de limite de compra dos clientes empresariais de forma
              automática e em tempo real no checkout;
            </p>
            <p className="mb-2">
              • Diferentes opções de recebimento de acordo com às necessidades e
            </p>
            <p className="mb-2">• APIs de integração.</p>
          </p>

          <p className="mb-0">
            <strong>3) Wallet do Lojista:</strong> <br />
          </p>
          <p>
            A primeira wallet B2B com diversas opções de crédito empresarial com
            foco em PMEs.
          </p>
          <p>
            <p className="mb-2">
              • Pagamento de fornecedores com saldo de maquininhas;
            </p>
            <p className="mb-2">
              • Capital de giro mais de 8x o volume mensal de vendas com
              parcelas flexíveis;
            </p>
            <p className="mb-2">
              • Antecipação de venda em qualquer maquininha com 1 clique;
            </p>
            <p className="mb-2">
              • Geração de cobranças: Pix, Boleto e Cartão e
            </p>
            <p className="mb-2">
              • O saldo de todas as maquininhas em um único lugar.
            </p>
          </p>
        </Fade>
      </div>

      {/* <Fade bottom>
                <h3 className="ras-author-title mt-60">Autor da publicação</h3>
            </Fade>
            <div className="ras-post-author">
                <Fade bottom>
                    <div className="author-img">
                        <img src={authorImage} alt="Author Image"/>
                    </div>
                </Fade>
                <Fade bottom>
                    <div className="ras-author-info">
                        <h3 className="author-name">Johnnatha Santos</h3>
                        <h4 className="author-designation">Co-Founder & CPTO CashToFlow</h4>
                        <p className="author-desc">Mais de 15 anos em tecnologia de meios
                            de pagamento, e-commerce e banco digital.</p>
                    </div>
                </Fade>
            </div> */}
    </>
  );
};

export default PostContentSobreCashToFlow;
