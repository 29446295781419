import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import normalLogo from '../../assets/images/logos/logo.svg';
import stickyLogo from '../../assets/images/logos/logo-colored.svg';

import {AppDomain} from "../../data/AppDomains";

const whatsApi =
	`https://api.whatsapp.com/send?phone=${AppDomain.contact.phone}`;

const HeaderStyleTwo = (props) => {
	const { headerClass, parentMenu, headerNormalLogo, headerStickyLogo, mobileNormalLogo } = props;

	const [menuOpen, setMenuOpen] = useState(false)
	const [isVisible, setIsVisible] = useState(false);	

	const [home, setHome] = useState(false)
	const [pages, setPages] = useState(false)

	const openMobileMenu = menu => {

		if (menu === 'home') {
			setHome(!home)
			setPages(false)
		}
		else if (menu === 'pages') {
			setHome(false)
			setPages(!pages)
		}
	};

    const location = useLocation();

	useEffect(() => {
		// Sticky is displayed after scrolling for 100 pixels
		const toggleVisibility = () => {
			if (window.pageYOffset > 100) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener("scroll", toggleVisibility);

		return () => window.removeEventListener("scroll", toggleVisibility);
	}, []);

	return (
		<React.Fragment>
			<div className={headerClass ? headerClass : 'ras-header ras-header-2 ras-header-2-alt'}>
				<div className={isVisible ? 'menu-bar menu-sticky sticky' : 'menu-bar menu-sticky'}>
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-2">
								<div className="logo">
									{
										isVisible ? 
										<Link to="/">
											<img src={headerStickyLogo ? headerStickyLogo : stickyLogo} alt="" />
										</Link> :
										<Link to="/">
											<img src={headerNormalLogo ? headerNormalLogo : normalLogo} alt="" />
										</Link>
									}
								</div>
							</div>
							<div className="col-lg-10 relative">
								<nav className="ras-main-menu">
									<ul className={menuOpen ? "nav-menu menu-open" : "nav-menu"}>

										<li className={location.pathname === '/fornecedor' ? 'active' : ''}>
											<Link to="/fornecedor">Fornecedor</Link>
										</li>

										<li className={location.pathname === '/lojista' ? 'active' : ''}>
											<Link to="/lojista">Lojista</Link>
										</li>

										<li className={location.pathname === '/contato' ? 'active' : ''}>
											<Link to="/contato">Contato</Link>
										</li>


										<li><a href={whatsApi} target="_blank">
											<i className="icofont-whatsapp me-2"></i>{AppDomain.contact.phoneFormatted}</a></li>

										<li><a href={AppDomain.appUrl} target={"_blank"}>Acesso</a></li>
									</ul>
									<button class={menuOpen ? "mobile-menu-icon open" : "mobile-menu-icon"} aria-label="Main Menu Icon" onClick={() => {setMenuOpen(!menuOpen)}}>
										<span></span>
										<span></span>
										<span></span>
									</button>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default HeaderStyleTwo;