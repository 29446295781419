import React from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

// Categories Image
import featureImg from '../../assets/images/features/seller-customer.png';

const Feature = () => {

    return (
        <div className="ras-features ras-features-2">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <Fade left>
                            <div className="ras-features-image">
                                <img src={featureImg} alt="our-features" />
                            </div>
                        </Fade>
                    </div>
                    <div className="col-lg-7">
                        <div className="ras-features-details">
                            <Fade right>
                                <h2>Empodere seu Time Comercial</h2>
                                <p>Aumente suas vendas: oferecendo aos clientes empresariais limite de crédito, com análise online na palma da mão do representante comercial.</p>
                            </Fade>
                            <div className="row features-items-wrapper">
                                <div className="col-md-6">
                                    <Fade bottom>
                                        <div className="features-item">
                                            <div className="features-icon purple-bg-icon">
                                                <i className="flaticon-start-up"></i>
                                            </div>
                                            <div className="features-content">
                                                <h5>Aumento das vendas
                                                </h5>
                                                <small>De 10 a 15% de aumento nas vendas, com esta super experiência de crédito online.</small>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-md-6">
                                    <Fade bottom>
                                        <div className="features-item">
                                            <div className="features-icon orange-bg-icon">
                                                <i className="flaticon-smiling-girl"></i>
                                            </div>
                                            <div className="features-content">
                                                <h5>Oferta de maiores prazos
                                                </h5>
                                                <small>Prazos flexíveis conforme a capacidade de venda mensal dos seus clientes.</small>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-md-6">
                                    <Fade bottom>
                                        <div className="features-item">
                                            <div className="features-icon skyblue-bg-icon">
                                                <i className="flaticon-customer-service"></i>
                                            </div>
                                            <div className="features-content">
                                                <h5>Adeus à inadimplência
                                                </h5>
                                                <small>Beneficie-se do risco zero.</small>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-md-6">
                                    <Fade bottom>
                                        <div className="features-item">
                                            <div className="features-icon red-bg-icon">
                                                <i className="flaticon-chat"></i>
                                            </div>
                                            <div className="features-content">
                                                <h5>Mais limite de crédito
                                                </h5>
                                                <small>Seus clientes com limite de 8 vezes o volume mensal de vendas.</small>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>

                            </div>
                            <Fade bottom>
                                <div className="features-btn text-left">
                                    <Link to="/contato" className="btn btn-large btn-primary">Fale com a gente</Link>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ras-blur-background"></div>
        </div>
    );
}

export default Feature;