import React from 'react';
import Fade from 'react-reveal/Fade';
import {AppDomain} from "../../data/AppDomains";

const mailApi =
    `mailto:${AppDomain.contact.mail}`;

const whatsApi =
    `https://api.whatsapp.com/send?phone=${AppDomain.contact.phone}`;

const ContactInfo = (props) => {
    const { contactBoxClass } = props;
    return (
        <div className={contactBoxClass ? contactBoxClass : 'ras-contact-address'}>
            <Fade left>
                <h2>Entrar em contato</h2>

                <h6 className="mb-0">Endereço:</h6>
                <p className="mb-10">{AppDomain.org.address.fullAdress}.</p>
                <h6 className="mb-0">E-mail:</h6>
                <p className="mb-10">
                    <a href={mailApi} target={"_blank"}>
                        {AppDomain.contact.mail}
                    </a>
                </p>
                <h6 className="mb-0">Whats:</h6>
                <p className="mb-10">
                    <a href={whatsApi} target={"_blank"}>
                        {AppDomain.contact.phoneFormatted}
                    </a>
                </p>
            </Fade>
        </div>
    );

}

export default ContactInfo;