import React from 'react';
import SearchWidget from '../../components/Widget/SearchWidget';
import RecentPostWidget from '../../components/Widget/RecentPostWidget';

import BlogData from "./BlogData";

import { useParams} from "react-router-dom";

const BlogMain = () => {

    let {url} = useParams();

    const page = BlogData.list.filter((p)=>{
        return p.url === url
    })

    const pagePost = page && page.length > 0 ? page[0].PostPage : ""

    return (
        <div className="ras-content sec-spacer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <div className="widget-area">
                            <SearchWidget />
                            <RecentPostWidget />
                            {/*<CategoriesWidget />*/}
                            {/*<TagsWidget />*/}
                        </div>
                    </div>

                    <div className="col-lg-8">

                        { pagePost}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogMain