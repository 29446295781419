
import { Link } from 'react-router-dom';

const RecentPost = (props) => {
    const { postClass, postImage, postTitle, postPublishedDate, url } = props;
    return (
        <div className={postClass ? postClass : 'ras-recent-item'}>
            <Link to="/blog/blog-details">
                <img 
                    src={postImage} 
                    alt={postTitle}
                    class="ras-recent-img"
                />
            </Link>
            <div class="ras-recent-item-info">
                <Link to={`/blog/blog-details/${url}`} class="recent-timeline">{postPublishedDate}</Link>
                <h3 class="ras-recent-title"><Link to={`/blog/blog-details/${url}`}>{postTitle}</Link></h3>
            </div>
        </div>
    )
}

export default RecentPost