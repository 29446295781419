import React from 'react';
import { Route, BrowserRouter as Router, Switch, withRouter } from 'react-router-dom';



//Custom Components

import Home from '../pages/home';
import Fornecedor from '../pages/fornecedor';
import Lojista from '../pages/lojista';
import About from '../pages/about';
import Blog from '../pages/blog';
import BlogDetails from '../pages/blog/blog-details';
import Service from '../pages/service';
import ServiceDetails from '../pages/service/service-details';
import CaseStudy from '../pages/case-study';
import CaseStudyDetails from '../pages/case-study/case-study-details';
import Contact from '../pages/contact';
import Error from '../pages/404';
import LoadTop from '../components/ScrollTop/LoadTop'
import Privacy from '../pages/about/Privacy';


const App = () => {
    return (
        <div className='App'>
            <Router>
                <LoadTop />
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/home" component={Home} />
                    <Route path="/fornecedor" component={Fornecedor} />
                    <Route path="/lojista" component={Lojista} />
                    <Route path="/about" component={About} />
                    <Route path="/blog" exact component={Blog} />
                    <Route path="/blog/blog-details/:url" component={BlogDetails} />
                    <Route path="/service" exact component={Service} />
                    <Route path="/service/service-details" component={ServiceDetails} />
                    <Route path="/case-study" exact component={CaseStudy} />
                    <Route path="/case-study/case-study-details" component={CaseStudyDetails} />
                    <Route path="/contato" component={Contact} />
                    <Route path="/privacidade" component={Privacy} />
                    <Route component={Error} />
                </Switch>
            </Router>

        </div>
    );
}

export default App;
