import React, { useState } from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

import postImage from "../../../assets/images/blog/fornecedores-a-detail.png";
import authorImage from "../../../assets/images/perfil.png";

const PostContentFornecedoresAmericanas = (props) => {
  return (
    <>
      <Fade bottom>
        <div className="ras-post-img">
          <img src={postImage} alt="Business Analysis for Growing Business" />
        </div>
      </Fade>
      <ul className="ras-post-meta">
        {/* <Fade bottom>
                    <li className="ras-post-user fadeInLeft">
                        <i className="icofont-user"></i>
                        <Link to="#">{props.blogAuthor}</Link>
                    </li>
                </Fade> */}
        <Fade bottom>
          <li className="ras-post-date fadeInLeft">
            <i className="icofont-ui-clock"></i>
            <span>{props.blogPublishedDate}</span>
          </li>
        </Fade>
        <Fade bottom>
          <li className="ras-post-tags fadeInLeft">
            <i className="icofont-tag"></i>
            <div className="ras-post-tag-list">
              <Link to="#">Fornecedor</Link>,&nbsp;
              <Link to="#">Risco de crédito</Link>,&nbsp;
              <Link to="#">Americanas</Link>
            </div>
          </li>
        </Fade>
      </ul>

      <Fade bottom>
        <h2 className="ras-post-title">{props.blogTitle}</h2>
      </Fade>
      <div className="ras-post-content">
        <Fade bottom>
          <p>
            A cadeia de Supply Chain atrelada as Americanas se torna uma
            “erosão”, iminente aos fundos de investimento, fornecedores e
            bancos.
          </p>
          <p>
            Os últimos eventos envolvendo as Lojas Americanas coloca em
            descrédito algumas classes de ativo muito utilizadas por fundos de
            investimento, principalmente, quando envolve Risco Sacado.
          </p>

          <blockquote className="blockquote">
            <i className="icofont-quote-right"></i>
            Fornecedores que vendem parcelado e antecipam ou não a nota fiscal
            ficam expostos a esse tipo de operação, pois estão assumindo pra si
            o risco do crédito.
          </blockquote>

          <p>
            Por mais que contratos de crédito ou desconto tenham sofisticados
            recursos de Loan Covenants, isso sem um mecanismo sistemático de
            liquidação de garantias consiste em disputas jurídicas e
            intermináveis liminares.
          </p>

          <p>
            Estamos aprendendo cada vez mais que crédito com mecanismo
            sistemático de liquidação de garantias, deve preencher as brechas
            que não podemos cobrir com Covenants e nem com refinadas esteiras de
            crédito.
          </p>
          <p>
            Atualmente, uma classe de ativo que torna viável mecanismo
            sistemático de liquidação de garantias, são os Recebíveis de
            Arranjos de Pagamento.
          </p>
          <p>
            O coração da tecnologia CashToFlow está na vanguarda desta grande
            mudança na dinâmica do crédito empresarial.
          </p>

          <p>
            <h3>Como estar prepado?</h3>
          </p>

          <p>
            Utilize a CashToFlow como motor de crédito e meio de pagamento Buy
            Now, Pay Later B2B, nossa plataforma possui mecanismo sistemático de
            liquidação de garantias, dessa forma, você não precisa mais assumir
            riscos desnecessários nas operações de vendas empresariais.
          </p>
        </Fade>
      </div>

      {/* <Fade bottom>
                <h3 className="ras-author-title mt-60">Autor da publicação</h3>
            </Fade>
            <div className="ras-post-author">
                <Fade bottom>
                    <div className="author-img">
                        <img src={authorImage} alt="Author Image"/>
                    </div>
                </Fade>
                <Fade bottom>
                    <div className="ras-author-info">
                        <h3 className="author-name">Johnnatha Santos</h3>
                        <h4 className="author-designation">Co-Founder & CPTO CashToFlow</h4>
                        <p className="author-desc">Mais de 15 anos em tecnologia de meios
                            de pagamento, e-commerce e banco digital.</p>
                    </div>
                </Fade>
            </div> */}
    </>
  );
};

export default PostContentFornecedoresAmericanas;
