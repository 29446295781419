import React from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import SingleService from '../../components/Service/SingleService';

const Service = () => {

    return (
        <div className="ras-services ras-services-1 ras-services-1-spacer gradient-bg mb-5">
            <div class="container">
                <h2 class="text-center fadeInUp mb-0">
                    Reduzimos o custo
                </h2>
                <h2 className="text-center fadeInUp">
                    de clientes com crédito e antecipação de recebíveis
                </h2>
                <h2 className="mb-50 text-center fadeInUp">
                    <span className="color-title">Alguns Benefícios para Lojistas</span>
                </h2>

                <Fade left cascade>
                    <div className="row align-items-center"> 
                        <div className="col-lg-4">
                            <SingleService
                                itemClass="ras-services-item"
                                Icon='flaticon-web-programming'
                                Title="Condições de pagamentos flexíveis"
                                Desc="Quer parcelas em até 3 vezes? sem problemas, a gente faz pra você e SEM JUROS. Ou, você quer parcelar em mais de 3 vezes? a gente faz também."
                            />
                        </div>
                        <div className="col-lg-4">
                            <SingleService
                                itemClass="ras-services-item fadeInLeft"
                                Icon='flaticon-digital-marketing'
                                Title="Compre hoje e Pague depois"
                                Desc="Com Cash2Pay a compra é na hora e o pagamento só depois. Parcelamos suas compras em seus fornecedores com condições flexíveis."
                            />
                        </div>
                        <div className="col-lg-4">
                            <SingleService
                                itemClass="ras-services-item fadeInLeft"
                                Icon='flaticon-vector'
                                Title="Super Agenda de Recebíveis"
                                Desc="As vendas de todas as maquininhas em um só lugar. Uma visão centralizada de seus recebíveis em todos credenciadores, subcredenciadores e marketplaces."
                            />
                        </div>
                    </div>
                </Fade>

            </div>
        </div>
    );
}

export default Service;