import React from 'react';
import {Link} from 'react-router-dom';

import Logo from '../../assets/images/logos/logo-colored.svg';
import insImg1 from '../../assets/images/instagram/insta-1.png';
import insImg2 from '../../assets/images/instagram/insta-2.png';
import insImg3 from '../../assets/images/instagram/insta-3.png';
import insImg4 from '../../assets/images/instagram/insta-4.jpg';
import {AppDomain} from "../../data/AppDomains";

const mailApi =
    `mailto:${AppDomain.contact.mail}`;

const whatsApi =
    `https://api.whatsapp.com/send?phone=${AppDomain.contact.phone}`;

const FooterTwo = (props) => {
    const {footerLogo, footerClass} = props;
    return (
        <footer className={footerClass ? footerClass : 'ras-footer ras-footer-2'}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 relative">
                        <div className="ras-calltoaction fadeInUp">
                            <h3>
                                <div className="row">
                                    <div className="col-12">
                                        Você não precisa mais <strong>correr riscos</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <strong>e perder vendas</strong>: fale com a gente.
                                    </div>
                                </div>
                            </h3>
                            <Link to="/contato" className="btn btn-primary btn-large">Contato</Link>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-12 ras-widget-about fadeInLeft relative">
                        <div className="ras-about-logo">
                            <Link to="/"><img src={Logo} alt="Footer Logo"/></Link>
                        </div>

                        <p className="ras-about-desc">Um novo jeito de
                            ajudar
                            <br/> o empreendedor no Brasil.</p>

                        <ul className="ras-social-profile">
                            <li><a href={AppDomain.socialMedia.instagram} target={"_blank"}><i
                                className="icofont-instagram"></i></a></li>
                            <li><a href={AppDomain.socialMedia.linkedin} target={"_blank"}><i
                                className="icofont-linkedin"></i></a></li>
                        </ul>
                    </div>
                    <div className="col-lg-8 col-12 relative">
                        <div className="row">

                            <div className="col-lg-4 col-6 ras-widget-instagram fadeInLeft">
                                <h3 className="ras-widget-title">Instagram</h3>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="ras-footer-insta-photo">
                                            <a href={AppDomain.socialMedia.instagram} target={"_blank"}>
                                                <img src={insImg1}
                                                                               alt="Utilize o saldo das maquininhas para efetuar pagamentos e obter as melhores ofertas de crédito."/></a>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="ras-footer-insta-photo">
                                            <a href={AppDomain.socialMedia.instagram} target={"_blank"}>
                                                <img src={insImg2} alt="Fornecedor não precisa mais correr riscos e perder vendas: Motor de Crédito e Checkout B2B na mesma Plataforma."/></a>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="ras-footer-insta-photo">
                                            <a href={AppDomain.socialMedia.instagram} target={"_blank"}>
                                                <img src={insImg3} alt="para #Fornecedores e #Lojistas"/></a>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="ras-footer-insta-photo">
                                            <a href={AppDomain.socialMedia.instagram} target={"_blank"}>
                                                <img src={insImg4}
                                                                          alt="Plataforma de Pagamentos B2B, para micros e pequenas empresas acessarem crédito, sem burocracia e sem taxas."/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8 col-12 ras-widget-subscribe fadeInLeft">
                                <div className="ras-about-address">
                                    <p>{AppDomain.org.name}<br/>
                                        CNPJ: {AppDomain.org.legalId}</p>
                                    <p className="mb-0">Contato:</p>
                                    <p><a href={whatsApi} target={"_blank"}>
                                        {AppDomain.contact.phoneFormatted}</a>
                                        <span className="email"><a href={mailApi}
                                                                   target={"_blank"}>{AppDomain.contact.mail}</a></span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ras-copyright">
                    <div className="row ras-copyright-wrapper relative fadeInUp">
                        <div className="col-md-6">
                            <p className="copyright-text text-left mb-0">
                                {AppDomain.copyright}
                                <Link to="/"></Link>
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </footer>
    );
}

export default FooterTwo;