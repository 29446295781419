import React from 'react';
import Fade from 'react-reveal/Fade';

import SingleService from '../../components/Service/SingleService';

import serviceShape from '../../assets/images/eclips-shape-1.svg';

const Service = () => {

    return (
        <div className="ras-services ras-services-1 ras-service-1-spacer-bottom">
            <img src={serviceShape} alt="service1-bg" className="ras-services-1-bg" />
            <div className="container">
                <Fade bottom>
                    <h2 className="mb-50 text-center">Nós ajudamos centenas <br />de <span className="color-title">fornecedores</span> à venderem mais com menos risco.<br />
                    <span class="color-title">Nossas vantagens</span></h2>
                </Fade>
                <Fade left cascade>
                    <div className="row">
                        <div className="col-lg-4">
                            <SingleService
                                itemClass="ras-services-item"
                                Icon='flaticon-start-up'
                                Title="Aumento das vendas"
                                Desc="According to elite we providing web Development
                                services to our honorable clients for business."
                            />
                        </div>
                        <div className="col-lg-4">
                            <SingleService
                                itemClass="ras-services-item"
                                Icon='flaticon-digital-marketing'
                                Title="Oferta de maiores prazos"
                                Desc="According to elite we providing web Development
                                services to our honorable clients for business."
                            />
                        </div>
                        <div className="col-lg-4">
                            <SingleService
                                itemClass="ras-services-item"
                                Icon='flaticon-checked'
                                Title="Adeus à inadimplência"
                                Desc="According to elite we providing web Development
                                services to our honorable clients for business."
                            />
                        </div>
                    </div>
                </Fade>
            </div>
        </div>
    );
}

export default Service;