import React from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import Counter from './CounterSection';

// Image
import aboutImg from '../../assets/images/features/app-full.png';

const Feature = () => {

    return (
        <div className="ras-features ras-features-2 ras-features-with-calltoaction zIndex">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div class="ras-calltoaction">
                            <Counter />
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <Fade bottom>
                            <div className="ras-features-image bordered-image">
                                <img src={aboutImg} alt="our-features" />
                            </div>
                        </Fade>
                    </div>
                    <div className="col-lg-7">
                        <div className="ras-features-details">
                            <Fade bottom>
                                <h2><strong># Lojista</strong> Compre agora, e pague só depois, <span className="color-title">tenha crédito </span>e prazo</h2>
                            </Fade>
                            <Fade bottom>
                                <p>Você pode facilmente pagar suas compras de negócios mais tarde e de acordo com as suas necessidades. Os métodos de pagamento da Cash2Pay, vão beneficia-lo de um verdadeiro arsenal de Crédito B2B para sua loja. </p>
                            </Fade>
                            <div className="row features-items-wrapper">
                                <div className="col-md-6">
                                    <Fade bottom>
                                        <div className="features-item">
                                            <div className="features-icon purple-bg-icon">
                                                <i className="flaticon-start-up"></i>
                                            </div>
                                            <div className="features-content">
                                                <h5>Capital de Giro</h5>
                                                <small>Limite de até 8 vezes seu volume mensal de vendas. </small>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-md-6">
                                    <Fade bottom>
                                        <div className="features-item">
                                            <div className="features-icon orange-bg-icon">
                                                <i className="flaticon-digital-marketing"></i>
                                            </div>
                                            <div className="features-content">
                                                <h5>Pague de Tudo com Antecipação Zero</h5>
                                                <small>Use o saldo de suas maquininhas para efetuar pagamentos sem juros.</small>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-md-6">
                                    <Fade bottom>
                                        <div className="features-item">
                                            <div className="features-icon skyblue-bg-icon">
                                                <i className="flaticon-customer-service"></i>
                                            </div>
                                            <div className="features-content">
                                                <h5>Antecipe com 1 clique</h5>
                                                <small>Antecipe as vendas de todas as suas maquininhas com 1 clique.</small>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-md-6">
                                    <Fade bottom>
                                        <div className="features-item">
                                            <div className="features-icon red-bg-icon">
                                                <i className="flaticon-smiling-girl"></i>
                                            </div>
                                            <div className="features-content">
                                                <h5>Cobranças</h5>
                                                <small>Envie cobranças: Pix, Cartão e Boleto para seus clientes.</small>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                            </div>
                            <Fade bottom>
                                <div className="features-btn text-left">
                                    <Link to="/contato" className="btn btn-large btn-primary">Começar agora<i className="icofont-long-arrow-right"></i></Link>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Feature;