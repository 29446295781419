import React, { useState } from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import ModalVideo from "react-modal-video";

import postImage from "../../../assets/images/blog/formo-hub-detail.png";
import videoImage from "../../../assets/images/blog/formo-hub-detail.png";
import authorImage from "../../../assets/images/perfil.png";

const PostContentExpansaoMorro = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(!isOpen);

  return (
    <>
      <Fade bottom>
        <div className="ras-post-img">
          <img src={postImage} alt="Business Analysis for Growing Business" />
        </div>
      </Fade>
      <ul className="ras-post-meta">
        {/* <Fade bottom>
                    <li className="ras-post-user fadeInLeft">
                        <i className="icofont-user"></i>
                        <Link to="#">{props.blogAuthor}</Link>
                    </li>
                </Fade> */}
        <Fade bottom>
          <li className="ras-post-date fadeInLeft">
            <i className="icofont-ui-clock"></i>
            <span>{props.blogPublishedDate}</span>
          </li>
        </Fade>
        <Fade bottom>
          <li className="ras-post-tags fadeInLeft">
            <i className="icofont-tag"></i>
            <div className="ras-post-tag-list">
              <Link to="#">Fornecedor</Link>,&nbsp;
              <Link to="#">Lojista</Link>,&nbsp;
              <Link to="#">Franquia</Link>
            </div>
          </li>
        </Fade>
      </ul>

      <Fade bottom>
        <h2 className="ras-post-title">{props.blogTitle}</h2>
      </Fade>
      <div className="ras-post-content">
        <Fade bottom>
          <p>
            Este texto é sobre nossa visita ao Morro da Cruz em Porto Alegre/RS,
            e como a CashToFlow deve ajudar o comerciante local em comprar
            estoque para aumentar suas vendas e sustentar o consumo.
          </p>
          <p>
            Inicio esta publicação com uma pergunta. E para responder farei um
            breve pano de fundo para contextualizar.
          </p>
          <p className="blockquote">
            <i className="icofont-quote-right"></i>
            Por que no Morro da Cruz em Porto Alegre/RS não existe nenhuma loja
            de calçados?
          </p>

          <p>
            Conversamos com o Michel Couto da Formô Hub, uma pessoa incansável e
            entusiasta em novas tecnologias, que leva muita inovação para o
            Morro da Cruz: sabe aquele tipo de pessoa que você quer conversar
            por horas e horas e não quer que a conversa acabe? Pois bem, ele
            juntou sua equipe, comerciantes locais e me deram algumas respostas
            surpreendentes.
          </p>
        </Fade>

        <Fade bottom>
          <div className="container">
            <ModalVideo
              channel="youtube"
              isOpen={isOpen}
              videoId="AvaE8ehJ6gs"
              onClose={() => {
                openModal();
              }}
            />
            <div className="row align-items-center">
              <div className="col-lg-12 p-0">
                <div className="ras-video-container">
                  <img src={videoImage} alt="Formo Hub" />
                  <div className="ras-overlay-image">
                    <div className="ras-video-overlay">
                      <a
                        className="ras-vid-icon ras-video-lightbox vbox-item more"
                        href="#"
                        onClick={() => {
                          openModal();
                        }}
                      >
                        <i className="icofont-ui-play"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>

        <Fade bottom>
          <p>
            Em primeiro lugar, pesquisas informais incentivadas com apoio da
            Formô Hub sinalizam mais de 68 mil habitantes no Morro da Cruz, essa
            população com suporte de um comércio sustentado por empreendedores
            aguerridos esbanjando muita criatividade.
          </p>

          <p>
            Diga-se de passagem, existem mais de 5 mil municípios no Brasil, e
            se o Morro da Cruz fosse munícipio isso o colocaria na posição 492;
            colocando-o assim, próximo de um município de porte médio em tamanho
            populacional, para fins de comparação Campo Bom/RS está na posição
            476.
          </p>

          <p>
            A gente já vem falando que o principal parceiro de crédito do
            comércio, são seus fornecedores, e muitos deles não conseguem dar
            crédito para comerciantes de regiões “contestadas”. As esteiras de
            crédito B2B não estão equipadas com recursos refinados capazes de
            analisar o real comportamento de vendas do pequeno e médio
            comerciante.
          </p>

          <p>
            Em torno de 10 a 15% de potenciais vendas B2B na relação fornecedor
            e lojista são negadas por falta de um instrumento eficiente de
            análise e concessão de crédito.
          </p>

          <p>
            Há muitos segmentos de negócios com enormes dificuldades de
            abastecerem as prateleiras dos comerciantes do Morro da Cruz, e isso
            vai desde minimercados até a carência por segmentos de calçados,
            tintas, móveis, colchões e etcetera.
          </p>

          <p>
            A CashToFlow vem com a missão de tornar mais eficiente a relação
            entre fornecedores e lojistas, com um arsenal completo de meios de
            pagamento especializado no canal de vendas B2B, criando mais
            competividade e fomentando o crescimento da economia local.
          </p>

          <p>
            <h3>O quê esperar dessa parceria?</h3>
          </p>
          <p>
            Queremos juntos com a Formô Hub abrir portas para fornecedores se
            conectarem com o comércio local do Morro da Cruz, incentivar novos
            nichos e segmentos de negócios, empoderando o pequeno e médio
            comerciante com mais variedade de produtos e opções de
            empreendimento.{" "}
          </p>

          <p>
            Parabenizo toda equipe da Formô Hub pela iniciativa de levar
            inovação ao Morro da Cruz, com agendas que vão desde novos negócios,
            mais competitividade, até aulas de programação de software,
            marketing digital e finanças, realmente um projeto inspirador.
          </p>
        </Fade>
      </div>

      {/* <Fade bottom>
                <h3 className="ras-author-title mt-60">Autor da publicação</h3>
            </Fade>
            <div className="ras-post-author">
                <Fade bottom>
                    <div className="author-img">
                        <img src={authorImage} alt="Author Image"/>
                    </div>
                </Fade>
                <Fade bottom>
                    <div className="ras-author-info">
                        <h3 className="author-name">Johnnatha Santos</h3>
                        <h4 className="author-designation">Co-Founder & CPTO CashToFlow</h4>
                        <p className="author-desc">Mais de 15 anos em tecnologia de meios
                            de pagamento, e-commerce e banco digital.</p>
                    </div>
                </Fade>
            </div> */}
    </>
  );
};

export default PostContentExpansaoMorro;
