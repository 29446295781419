import React from 'react';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';

const CategoriesWidget = () => {
    return (
        <div className="ras-widget-menu-categories mt-40">
            <Fade bottom>
                <h3 className="ras-widget-title">Categorias</h3>
            </Fade>
            <Fade bottom>
                <ul className="mt-40">
                    <li>
                        <Link to="#">Fornecedores</Link>
                        <ul>
                            <li><Link to="#">Motor de Crédiro</Link></li>
                            <li><Link to="#">Checkout B2B</Link></li>
                        </ul>
                    </li>
                    <li>
                        <Link to="#">Lojistas</Link>
                        <ul>
                            <li><Link to="#">Wallet do Lojista</Link></li>
                            <li><Link to="#">Limite para compras de estoque</Link></li>
                            <li><Link to="#">Antecipe vendas com 1 clique</Link></li>
                        </ul>
                    </li>
                    <li>
                        <Link to="#">FIDCs, Securitizadoras, Factory e Bancos</Link>
                        <ul>
                            <li><Link to="#">Plataforma Whitelabel</Link></li>
                        </ul>
                    </li>
                    <li>
                        <Link to="#">Sistemas de ERP</Link>
                        <ul>
                            <li><Link to="#">APIs de Integração</Link></li>
                        </ul>
                    </li>
                </ul>
            </Fade>

        </div>
    )
}

export default CategoriesWidget;