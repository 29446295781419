export const AppDomain = {
  copyright: "© CashToFlow",
  org: {
    name: "CASH TO FLOW PAGAMENTOS LTDA",
    legalId: "44.353.163/0001-23",
    address: {
      fullAdress: "Av Silvio Sanson 1099, Sala 15B, Guaporé/RS.",
    },
  },
  socialMedia: {
    instagram: "https://www.instagram.com/cashtf.com.br/",
    linkedin: "https://www.linkedin.com/company/cash-top-flow",
  },
  contact: {
    phone: "5554992698772",
    phoneFormatted: "+55 (54) 99269-8772",
    mail: "contato@cashtf.com",
  },
  appUrl: "https://app.cash2pay.com.br",
};
